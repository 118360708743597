import React from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'

const TOS = (props) => (
  <Layout>
    <Helmet
      htmlAttributes={{ lang: 'en' }}
      title="Flotherm - Website Terms and Conditions"
      meta={[
        {
          name: 'description',
          content: 'Terms and Conditions',
        },
      ]}
    ></Helmet>

    <div id="main" className="wrapper style1">
      <div className="container">
        <header className="major">
          <h2>Terms and Conditions</h2>
          <p>
            These terms and conditions outline the rules and regulations for the
            use of Flotherm's Website.
          </p>
        </header>

        <section id="content">
          <h3>General</h3>
          <p>
            By accessing this website we assume you accept these terms and
            conditions in full. Do not continue to use Flotherm's website if you
            do not accept all of the terms and conditions stated on this page.
          </p>
          <p>
            The following terminology applies to these Terms and Conditions,
            Privacy Statement and Disclaimer Notice and any or all Agreements:
            "Client", "You" and "Your" refers to you, the person accessing this
            website and accepting the Company's terms and conditions. "The
            Company", "Ourselves", "We", "Our" and "Us", refers to our Company.
            "Party", "Parties", or "Us", refers to both the Client and
            ourselves, or either the Client or ourselves. All terms refer to the
            offer, acceptance and consideration of payment necessary to
            undertake the process of our assistance to the Client in the most
            appropriate manner, whether by formal meetings of a fixed duration,
            or any other means, for the express purpose of meeting the Client's
            needs in respect of provision of the Company's stated
            services/products, in accordance with and subject to, prevailing law
            of the United States of America. Any use of the above terminology or
            other words in the singular, plural, capitalisation and/or he/she or
            they, are taken as interchangeable and therefore as referring to
            same.
          </p>
          <h3>Cookies</h3>
          <p>
            We may employ the use of cookies. By using Flotherm's website you
            consent to the use of cookies in accordance with Flotherm's privacy
            policy.
          </p>
          <h3>License</h3>
          <p>
            Unless otherwise stated, Flotherm and/or it's licensors own the
            intellectual property rights for all material on Flotherm. All
            intellectual property rights are reserved. You may view and/or print
            pages from https://flothermtcd.com for your own personal use subject
            to restrictions set in these terms and conditions.
          </p>
          <p>You must not:</p>
          <ol>
            <li>Republish material from https://flothermtcd.com</li>
            <li>
              Sell, rent or sub-license material from https://flothermtcd.com
            </li>
            <li>
              Reproduce, duplicate or copy material from https://flothermtcd.com
            </li>
            <li>
              Redistribute content from Flotherm (unless content is specifically
              made for redistribution).
            </li>
          </ol>
          <h3>Reservation of Rights</h3>
          <p>
            We reserve the right at any time and in our sole discretion to
            request that you remove all links or any particular link to our Web
            site. You agree to immediately remove all links to our Web site upon
            such request. We also reserve the right to amend these terms and
            conditions and its linking policy at any time. By continuing to link
            to our Web site, you agree to be bound to and abide by these linking
            terms and conditions.
          </p>
          <h3>Removal of links from our website</h3>
          <p>
            If you find any link on our Web site or any linked web site
            objectionable for any reason, you may contact us about this. We will
            consider requests to remove links but will have no obligation to do
            so or to respond directly to you.
          </p>
          <p>
            Whilst we endeavour to ensure that the information on this website
            is correct, we do not warrant its completeness or accuracy; nor do
            we commit to ensuring that the website remains available or that the
            material on the website is kept up to date.
          </p>
          <h3>Content Liability</h3>
          <p>
            We shall have no responsibility or liability for any content
            appearing on your Web site. You agree to indemnify and defend us
            against all claims arising out of or based upon your Website. No
            link(s) may appear on any page on your Web site or within any
            context containing content or materials that may be interpreted as
            libelous, obscene or criminal, or which infringes, otherwise
            violates, or advocates the infringement or other violation of, any
            third party rights.
          </p>
          <h3>Disclaimer</h3>
          <p>
            To the maximum extent permitted by applicable law, we exclude all
            representations, warranties and conditions relating to our website
            and the use of this website (including, without limitation, any
            warranties implied by law in respect of satisfactory quality,
            fitness for purpose and/or the use of reasonable care and skill).
            Nothing in this disclaimer will:
          </p>
          <ul>
            <li>
              limit or exclude our or your liability for death or personal
              injury resulting from negligence;
            </li>
            <li>
              limit or exclude our or your liability for fraud or fraudulent
              misrepresentation;
            </li>
            <li>
              limit any of our or your liabilities in any way that is not
              permitted under applicable law; or
            </li>
            <li>
              exclude any of our or your liabilities that may not be excluded
              under applicable law.
            </li>
          </ul>
          <p>
            The limitations and exclusions of liability set out in this Section
            and elsewhere in this disclaimer: (a) are subject to the preceding
            paragraph; and (b) govern all liabilities arising under the
            disclaimer or in relation to the subject matter of this disclaimer,
            including liabilities arising in contract, in tort (including
            negligence) and for breach of statutory duty.
          </p>
          <p>
            To the extent that the website and the information and services on
            the website are provided free of charge, we will not be liable for
            any loss or damage of any nature.
          </p>
          <h3>Credit & Contact Information</h3>
          <p>
            These Terms and conditions were generated at{' '}
            <a href="https://termsandconditionstemplate.com/privacy-policy-generator/">
              termsandconditionstemplate.com
            </a>
            . If you have any questions regarding any of our terms, please
            contact us via <a href="mailto:site@flothermtcd.com">email</a>.
          </p>
        </section>
      </div>
    </div>
  </Layout>
)

export default TOS
